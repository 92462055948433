/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import WorkWe from '../../components/common/workWe';
import Layout from '../../components/layout';

import img1 from '../../images/grupo_45.png';
import img2 from '../../images/img_9929.png';
import joseIsidro from '../../images/jose_isidro.png';
import marisol from '../../images/marisol_sanchez.png';
import bianey from '../../images/bianey.png';
import eduardo from '../../images/eduardo_avitia.png';
import alejandro from '../../images/alejandro.png';
import defaultProfile from '../../images/team_default.png';

import './nosotros.css';

const teamsArray = [{
  img: joseIsidro,
  job: 'CEO',
  linkeding: 'https://www.linkedin.com/in/isidro-burgos-67034b209',
  name: 'José Isidro burgos',
},
{
  img: marisol,
  job: 'Product Owner/UX-UI',
  linkeding: 'https://www.linkedin.com/in/marisolsanchezsantanna',
  name: 'Marisol Sánchez Santanna',
},
{
  img: eduardo,
  job: 'Consultor de TI/Full Stack ',
  linkeding: 'https://www.linkedin.com/in/avitia',
  name: 'Eduardo Avitia',
},
{
  img: bianey,
  job: 'Marketing Digital',
  linkeding: 'https://www.linkedin.com/in/bianey-libardi-lugo-garcia-8417b3159',
  name: 'Bianey Libardi',
},
{
  img: alejandro,
  job: 'Diseño Gráfico',
  linkeding: 'https://www.linkedin.com/in/alejandro-a-ba13b630',
  name: ' Alejandro Alvarado',
},
{
  img: defaultProfile,
  job: ' Desarrollador Android',
  linkeding: '',
  name: '',
},
{
  img: defaultProfile,
  job: 'Desarrollador iOS',
  linkeding: '',
  name: '',
},
{
  img: defaultProfile,
  job: 'Full Stack',
  linkeding: '',
  name: 'Roberto Medina',
},
{
  img: defaultProfile,
  job: 'Full Stack',
  linkeding: '',
  name: 'Luis Héctor Arredondo',
},
{
  img: defaultProfile,
  job: 'Desarrollador Android',
  linkeding: '',
  name: 'Jorge Barraza',
}, {
  img: defaultProfile,
  job: 'Full Stack',
  linkeding: 'https://www.linkedin.com/in/luis-hector-arredondo-lugo-9a222013b',
  name: 'Roberto Medina',
}];

const NosotrosIindex = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout whiteTheme>
      <>
        <div className="banner-nosotros banner-size">
          <div className="banner-nosotros-div">
            <h1 className="title">{t('weUs.title')}</h1>

            <span>{t('weUs.subTitle')}</span>
          </div>
        </div>

        <div className="container work-we-top">
          <WorkWe />
        </div>

        <div className="container">
          <div>
            <h2 className="text-primary">{t('weUs.ebox')}</h2>

            <p className="text-primary">{t('weUs.desc')}</p>
          </div>

          <section className="section-we">
            <article className="row">
              <div className="">
                <img alt="" className="we-post" src={img1} />
              </div>

              <div className="col w-min-300 w-max-400">
                <div className="">
                  <h2 className="text-primary p-0 m-0 m-v-16">
                    <span className="h2-plus">+</span>
                    {t('weUs.mision')}
                  </h2>

                  <p className="text-primary">
                    {t('weUs.misionDesc.part1')}
                    <strong>{` ${t('weUs.misionDesc.part2')}`}</strong>
                    {` ${t('weUs.misionDesc.part3')} `}
                    <strong>{`${t('weUs.misionDesc.part4')} `}</strong>
                    {t('weUs.misionDesc.part5')}
                    <strong>{` ${t('weUs.misionDesc.part6')} `}</strong>
                    {t('weUs.misionDesc.part7')}
                  </p>
                </div>

                <div className="line" />

                <div className="col">
                  <h2 className="text-primary p-0 m-0 m-v-16">
                    <span className="h2-plus">+</span>
                    {t('weUs.vision')}
                  </h2>

                  <p className="text-primary">
                    {t('weUs.visionDesc.part1')}
                    <strong>
                      {` ${t('weUs.visionDesc.part2')} `}
                    </strong>
                    {t('weUs.visionDesc.part3')}
                  </p>
                </div>
              </div>
            </article>

            <article className="row">
              <div className="col w-min-300 w-max-400">
                <h2 className="text-primary p-0 m-0 m-v-16">
                  {t('weUs.article.title')}
                </h2>

                <p className="text-primary">
                  <span className="h2-plus">+</span>
                  {t('weUs.article.desc.part1')}
                  <strong>{` ${t('weUs.article.desc.part2')} `}</strong>
                  {t('weUs.article.desc.part3')}
                </p>

                <p className="text-primary">
                  <span className="h2-plus">+</span>
                  {t('weUs.article.desc2.part1')}
                  <strong>{` ${t('weUs.article.desc2.part2')}`}</strong>
                  {t('weUs.article.desc2.part3')}
                  <strong>{` ${t('weUs.article.desc2.part4')}`}</strong>
                </p>

                <p>
                  <span className="h2-plus">+</span>
                  {t('weUs.article.desc3.part1')}
                  <strong>{` ${t('weUs.article.desc3.part2')}` }</strong>
                  {t('weUs.article.desc3.part3')}
                </p>
              </div>

              <div>
                <img alt="" className="we-post" src={img2} />
              </div>
            </article>
          </section>
        </div>

        <div className="wrapper-content we-background-color p-v-8">
          <div className="container work-team-div">
            <div className="flex col">
              <h2 className="text-primary p-0 m-0">{t('weUs.article.team')}</h2>

              <span className="text-primary p-v-8">{t('weUs.article.teamDesc')}</span>
            </div>

            {/* <div className="row center we-people">
              {teamsArray.map((item, index) => (
                <div className="work-team-post" key={String(index)}>
                  <div className="img-container center flex col-4">
                    <img alt={item.name} className="profile-img" src={item.img} />

                    <a className="img-we-link" href={item.linkeding}
                    target="_blank" rel="noreferrer">
                      <img alt="linkeding" src={linkeding} />
                    </a>
                  </div>

                  <div className="col">
                    <span className="name">{item.name}</span>
                    <span className="text-primary">{item.job}</span>
                  </div>
                </div>
              ))}
            </div> */}
          </div>
        </div>

        <div className="container work-we-bottom">
          <WorkWe />
        </div>
      </>
    </Layout>
  );
};

export default NosotrosIindex;
